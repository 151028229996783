<template>
  <section class="whyReglory">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <section class="container">
      <section class="title">
        <el-image :src="options.img1"></el-image>
        <p class="ch"><strong>为什么是我们？</strong></p>
        <div class="line"></div>
        <p class="en"><strong>Why Reglory</strong></p>
      </section>
      <el-image :src="options.img2" class="img1"></el-image>
      <el-row :gutter="30" class="main">
        <el-col :span="8"><div class="details">我们深耕行业多年，可以精准把握行业的个性化需求和相关痛点，并持续从为客户创造价值的角度出发，提高信息化管理水平及销售订单；</div></el-col>
        <el-col :span="8"><div class="details">我们具有丰富的供应链信息系统的产品经验和项目实施经验，能够精准的为中小企业解决信息化的难点，在做好企业内控管理的同时，积极为企业拓展市场；</div></el-col>
        <el-col :span="8"><div class="details">我们具有专业的技术研发团队和强大的自主研发能力，已经成功研发出助力飞相关产品；时刻为给企业赋能做足重新的准备。</div></el-col>
      </el-row>
      <el-image :src="options.img5" class="img1"></el-image>
    </section>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
export default {
  name: 'zhulifei',
  components: {
    imageBanner,
  },
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/05-助力飞.png'),
        slogan: require('../../assets/images/common/slogan.png'),
      },
      options: {
        img1: require('../../assets/images/2023/zhulifei/页面3-1.png'),
        img2: require('../../assets/images/2023/zhulifei/页面3-2.png'),
        img5: require('../../assets/images/2023/zhulifei/页面图_02.png'),
      }
    }
  },
  watch: {
    '$route.query' () {
      this.getOptions()
    }
  },
  created () {
    this.getOptions()
  },
  methods: {
    getOptions () {
      // let id = (this.$route.query.id).toString()
      // switch (id) {
      //   case '1':
      //     this.options = this.$store.getters.getTeam1 && JSON.parse(JSON.stringify(this.$store.getters.getTeam1))
      //     break
      //   case '2':
      //     this.options = this.$store.getters.getTeam2 && JSON.parse(JSON.stringify(this.$store.getters.getTeam2))
      //     break
      //   case '3':
      //     this.options = this.$store.getters.getTeam3 && JSON.parse(JSON.stringify(this.$store.getters.getTeam3))
      //     break
      //   case '4':
      //     this.options = this.$store.getters.getTeam4 && JSON.parse(JSON.stringify(this.$store.getters.getTeam4))
      //     break
      //   default:
      //     this.options = this.$store.getters.getTeam1 && JSON.parse(JSON.stringify(this.$store.getters.getTeam1))
      //     break
      // }
    }
  }
}
</script>

<style scoped>
.whyReglory {background-color: #ecf0f5; padding-bottom: 1rem;}
.container {padding: 1rem 10%;}
.whyReglory .title {text-align: center; padding: 1rem 0 2rem;}
.whyReglory .title .el-image {width: auto;}
.whyReglory .ch, .whyReglory .en {font-size: 3rem}
.whyReglory .ch {color: #475f76}
.whyReglory .en {color: #ccc;}
.whyReglory .line {width: 40%; height: 2px; background-color: #ccc; margin: 0 auto;}
.container p {line-height: 2; text-indent: 2em;}
.container .details {padding: 0 2rem; line-height: 2;}
.whyReglory .main {padding: 0 0 1rem;}
.whyReglory .img1 {padding: 2rem 0; width: 100%;}
</style>